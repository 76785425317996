import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  message,
  Select,
  Space,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import useChemicalLicenseList from "../../hooks/useChemicalLicenseList";
import NetworkApi from "../../network/networkApi";
interface Props {
  visible: boolean;
  onHide: () => void;
}
const WarehouseChemicalAdd = ({ visible, onHide }: Props) => {
  const formRef = useRef<any>();
  const [selectedChemicalType, changeSelectedChemicalType] = useState<any>("");

  const [licenseList, _loadLicense, licenseFilter] = useChemicalLicenseList();
  useEffect(() => {
    if (visible) {
      formRef.current?.resetFields();
      licenseFilter({
        status: "active",
        type: ["import", "importsale"],
      });
    }
  }, [visible]);
  const onFinish = useCallback(async (value: any) => {
    if (value.importDate) {
      value.importDate = value.importDate.format("YYYY-MM-DD");
    }
    let response = await NetworkApi.requestPost(
      "/api/warehouse/chemical/import",
      value
    );
    if (response.resultCode == 200) {
      onHide();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  return (
    <Modal
      onCancel={onHide}
      visible={visible}
      title={"Бүтээгдэхүүн нэмэх"}
      footer={null}
    >
      <Form layout="vertical" ref={formRef} onFinish={onFinish}>
        <Form.Item name="licenseId" label="Зөвшөөрөл:">
          <Select
            onSelect={(v) => {
              const license = licenseList.result.find((item) => item.id == v);
              changeSelectedChemicalType(license?.chemical?.type);
            }}
          >
            <Select.Option value="">Зөвшөөрөл</Select.Option>
            {licenseList.result.map((t) => (
              <Select.Option value={t.id}>
                {t.chemical?.technicalName}-{t.remaining}
                {t.chemical?.unit?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="importDate" label="Импортлосон/Үйлдвэрлэсэн огноо:">
          <DatePicker placeholder={"Огноо"} />
        </Form.Item>
        <Form.Item name="amount" label="Бодис, бордооны хэмжээ(кг):">
          <InputNumber parser={(value) => value?.replace(",", ".") || ""} />
        </Form.Item>
        {selectedChemicalType?.hasParcel && (
          <Form.Item name="parcel" label="Сав баглааны хэмжээ(ш):">
            <InputNumber parser={(value) => value?.replace(",", ".") || ""} />
          </Form.Item>
        )}
        <Space size={16}>
          <Button type="primary" htmlType="submit">
            Хадгалах
          </Button>
          <Button onClick={() => onHide()}>Болих</Button>
        </Space>
      </Form>
    </Modal>
  );
};
export default WarehouseChemicalAdd;
