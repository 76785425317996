import { useCallback, useEffect, useState } from "react";
import { InputNumber } from "antd";
import { debounce } from "lodash";

interface PlanAreaInputProps {
  area: number;
  max: number;
  onChange: any;
}

const PlanAreaInput = ({ area, max, onChange }: PlanAreaInputProps) => {
  const [v, setV] = useState(area);

  useEffect(() => {
    onChange(v);
  }, [v]);

  useEffect(() => {
    setV(area);
  }, [area]);

  const changeHandler = (value?: string) => {
    console.log(value);
    if (!value) {
      setV(0);
      return;
    }
    if (max >= +value) {
      setV(+value);
      return;
    }
    if (max < +value) {
      setV(max);
      return;
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [
    max,
  ]);

  return (
    <InputNumber
      className="custom-number-input"
      value={`${v}`}
      formatter={(value?: string) =>
        `${value ?? 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      step="0.1"
      parser={(value?: string) => value!.replace(/(,*)/g, "")}
      onChange={debouncedChangeHandler}
    />
  );
};

export default PlanAreaInput;