import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Upload,
  message,
  Spin,
  List,
  Image,
  Card,
  Popconfirm,
  Table,
  InputNumber,
  Modal,
} from "antd";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import config from "../../config";
import useWarehouseFarmerList from "../../hooks/useWarehouseFarmerList";
import useCertificateDetail from "../../hooks/useCertificateDetail";
import moment from "moment";
import useUserPlantWarehouse from "../../hooks/useUserPlantWarehouse";
import useUserSortWarehouse from "../../hooks/useUserSortWarehouse";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";
import { debounce, size } from "lodash";
import useFarmlandChemicalList from "../../hooks/useFarmlandChemicalList";
import useFarmlandServiceList from "../../hooks/useFarmlandServiceList";
const Option = Select.Option;
const { TextArea } = Input;
const { Dragger } = Upload;
const host = config.get("apiUrl");
interface Params {
  id?: any;
}
const LicenseAdd = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams<Params>();
  const history = useHistory();
  const [fileList, changeFileList] = useState<any[]>([]);
  const [initiatedValues, setInitiatedValues] = useState(true);

  const [initedFarmer, changeInitedFarmer] = useState(false);
  const [farmers, loadFarmers, changeFarmers] = useWarehouseFarmerList();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [usageId, setUsageId] = useState(0);
  const [content, loadContent, changeId] = useCertificateDetail();
  const [licenseForm] = Form.useForm();

  useEffect(() => {
    licenseForm.setFieldsValue({
      fileList,
    });
  }, [fileList]);

  const [userPlantWarehouse, , changeUserPlantWarehouseFilter] =
    useUserPlantWarehouse();
  const [userSortWarehouse, , changeUserSortWarehouseFilter] =
    useUserSortWarehouse();
  const [warehouseType, setWarehouseType] = useState("harvest");
  const [userFarmland, , userFarmlandFilter, changeUserFarmlandFilter] =
    useUserFarmland();
  const [farmlandState, setFarmlandState] = useState<any>();
  const [aimag, , changeAimag] = useAddress();
  const [sum, , changeSum] = useAddress();
  const [bag, , changeBag] = useAddress();
  // const [usageId, setUsageId] = useState<any>();

  useEffect(() => {
    changeAimag(1);
  }, []);

  useEffect(() => {
    if (user?.role.cd === USER_ROLE.CUSTOMER) {
      changeFarmers({ user, rd: user.rd });
    }
  }, [user]);
  useEffect(() => {
    if (user?.role.cd === USER_ROLE.CUSTOMER && farmers.result.length > 0) {
      licenseForm.setFieldsValue({
        userId: {
          key: farmers.result[0].id,
          id: farmers.result[0].id,
          label: [farmers.result[0].name, "-", farmers.result[0].rd],
        },
      });
      if (farmers.result.length === 1) {
        setSelectedOrganization(farmers.result[0].id);
        changeUserFarmlandFilter({
          ...userFarmlandFilter,
          organization: farmers.result[0].id,
        });
      }
    } else if (user?.role.cd === USER_ROLE.CUSTOMER) {
      if (initedFarmer) {
        message.error(
          "Танд одоогоор гэрчилгээ авах боломжтой бүтээгдэхүүн байхгүй байна."
        );
      }
      licenseForm.setFieldsValue({
        userId: undefined,
      });
    }
    changeInitedFarmer(true);
  }, [farmers.result]);
  useEffect(() => {
    if (id) {
      changeId(id);
      setInitiatedValues(true);
    }
  }, [id]);
  useEffect(() => {
    if (id && !content.loading) {
      let values = {
        ...content.result,
      };
      if (content?.result?.sizes?.length > 0) {
        const warehousePlantId = content.result.sizes.map(
          (item: any) => item.warehouse.plantId
        );
        setSelectedOrganization(content.result.sizes[0].warehouse.owner.id);
        changeFarmers({
          user,
          rd: content.result.sizes[0].warehouse.owner.rd,
        });
        changeUserFarmlandFilter({
          organization: content.result.sizes[0].warehouse.owner.id,
        });
        values.userId = {
          key: content.result.sizes[0].warehouse.owner.id,
          id: content.result.sizes[0].warehouse.owner.id,
          label: [
            content?.result.sizes[0].warehouse.owner.name,
            "-",
            content?.result.sizes[0].warehouse.owner.rd,
          ],
        };
        values.warehousePlantId = warehousePlantId;
        values.farmlandId =
          content?.result.sizes[0].warehouse.farmlandPlant.farmlandId;
        changeUserPlantWarehouseFilter({
          type: "harvest",
          ownerId: content.result.sizes[0].warehouse.owner.id,
          farmlandId:
            content?.result.sizes[0].warehouse.farmlandPlant.farmlandId,
        });
        changeUserSortWarehouseFilter({
          type: "harvest",
          ownerId: content.result.sizes[0].warehouse.owner.id,
          farmlandId:
            content?.result.sizes[0].warehouse.farmlandPlant.farmlandId,
          "plantId[]": warehousePlantId,
        });
      }
      values.date = moment(content.result.startDate).isValid()
        ? [moment(content.result.startDate), moment(content.result.endDate)]
        : [];

      values.aimagId = content.result.receiverAimagId;
      values.sumId = content.result.receiverSumId;
      values.bagId = content.result.receiverBagId;

      if (content.result.files) {
        changeFileList(JSON.parse(content.result.files));
      }
      licenseForm.setFieldsValue(values);
    }
  }, [id, content, licenseForm]);

  useEffect(() => {
    let filter = {};
    if (warehouseType) {
      filter = {
        ...filter,
        type: warehouseType,
      };
    }
    if (selectedOrganization) {
      filter = {
        ...filter,
        ownerId: selectedOrganization,
      };
    }
    if (farmlandState) {
      filter = {
        ...filter,
        farmlandId: farmlandState,
      };
    }
    changeUserPlantWarehouseFilter(filter);
  }, [selectedOrganization, warehouseType, farmlandState]);

  const [userSort, setUserSort] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    // const sorts = [...userSort, ...userSortWarehouse.result];
    if (
      initiatedValues &&
      !content.loading &&
      content.result?.sizes?.length > 0
    ) {
      setInitiatedValues(false);
      const sortSizes = content.result.sizes.map(
        ({
          warehousePlantId,
          area,
        }: {
          warehousePlantId: number;
          area: number;
        }) => ({ id: warehousePlantId, size: area })
      );

      const sorts = userSortWarehouse.result.map((item) => {
        const sortSize = sortSizes.find((si: any) => +si.id === +item.id);
        return {
          ...item,
          size: sortSize?.size ?? "0",
        };
      });
      setUserSort(sorts);
    } else {
      const memorySortIds = userSortWarehouse.result.map((item) => item.id);
      const memorySort = userSort.filter((item) =>
        memorySortIds.includes(item.id)
      );
      const existedSortIds = memorySort.map((item) => item.id);
      const addedSorts = userSortWarehouse.result.filter(
        (item) => !existedSortIds.includes(item.id)
      );
      setUserSort([...memorySort, ...addedSorts]);
    }
  }, [userSortWarehouse]);

  const sortColumns: any = [
    {
      title: "Бүтээгдэхүүний нэр",
      dataIndex: "sort",
      render: (sort: any) => `${sort?.plant.name}`,
    },
    {
      title: "Сорт",
      dataIndex: "sort",
      render: (sort: any, item: any) =>
        `${sort?.name ?? ""} - ${item?.remaining ?? "0"} ${
          item.sort?.unit?.name ?? ""
        }`,
    },
    {
      title: "ОУ-ын код",
      dataIndex: "sort",
      render: (sort: any) => `${sort?.internationalCode ?? "-"}`,
    },
    {
      title: "Хэмжээ(кг)",
      dataIndex: "size",
      width: "200px",
      render: (value: string, record: any, index: number) => (
        <InputSize
          maxSize={record.remaining}
          size={value ? +value : 0}
          onChange={(size: number) => {
            const ns = [...userSort];
            ns[index].size = size;
            setUserSort(ns);
          }}
        />
      ),
    },
    {
      title: "Үйлдэл",
      key: "x",
      render: (text: any, record: any) => (
        <span
          style={{ fontWeight: "bold" }}
          onClick={(e) => {
            onDelete(record.id, e);
          }}
        >
          Устгах
        </span>
      ),
    },
  ];

  const onDelete = (key: any, e: any) => {
    e.preventDefault();
    const data = userSort.filter((item) => item.id !== key);
    setUserSort(data);
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      let data: any = {};
      if (values.date && values.date.length == 2) {
        data["startDate"] = values.date[0].format("YYYY/MM/DD");
        data["endDate"] = values.date[1].format("YYYY/MM/DD");
      } else {
        message.error("Хүчинтэй хугацааг оруулна уу");
        return;
      }
      if (userSort.length === 0) {
        message.error("Сорт оруулна уу");
        return;
      }
      const sizes: any[] = [];
      let totalSize = 0;
      for (const sort of userSort) {
        totalSize += +sort.size;
        sizes.push({
          warehousePlantId: sort.id,
          area: sort.size,
        });
      }
      if (totalSize === 0) {
        message.error(
          "Гэрчилгээний бүтээгдэхүүний нийт хэмжээ 0-ээс их байх ёстой"
        );
        return;
      }
      data["sellerId"] = selectedOrganization;
      data["sizes"] = sizes;
      data["files"] = JSON.stringify(fileList);
      data["purpose"] = values.purpose;
      data["receiverName"] = values.receiverName;
      data["receiverPhone"] = values.receiverPhone;
      data["aimagId"] = values.aimagId;
      data["sumId"] = values.sumId;
      data["bagId"] = values.bagId;
      data["transporterName"] = values.transporterName;
      data["transporterPhone"] = values.transporterPhone;
      data["transporterId"] = values.transporterId;
      data["description"] = values.description;
      data["status"] = values.status;
      data["farmlandId"] = values.farmlandId;
      data["examinationDescription"] = values.examinationDescription;
      let response = await NetworkApi.requestPost("/api/certificate/create", {
        ...data,
        id,
      });
      if (response.resultCode == 200) {
        history.push("/origin/list");
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [history, userSort, fileList, id]
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // @ts-ignore
  return (
    <>
      <BreadMe title={["Гэрчилгээ", "Бүртгэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={16} push={4}>
            <Form
              form={licenseForm}
              onFinish={(data) => {
                handleSubmit(data);
              }}
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
              name="basic"
            >
              <Form.Item
                label="Тариаланчийн регистрийн дугаар"
                name="userId"
                rules={[
                  {
                    required: true,
                    message: "Тариаланчийн регистрийн дугаар оруулна уу",
                  },
                ]}
              >
                <Select
                  disabled={user.role.cd === USER_ROLE.CUSTOMER}
                  placeholder="Тариаланч"
                  onChange={(e: any) => {
                    setSelectedOrganization(e.value);
                    changeUserFarmlandFilter({ organization: e.value });
                    licenseForm.setFieldsValue({
                      farmlandId: undefined,
                      warehousePlantId: undefined,
                    });
                  }}
                  showSearch
                  labelInValue
                  style={{ width: "80%" }}
                  filterOption={false}
                  onSearch={(rd) => changeFarmers({ user, rd })}
                  notFoundContent={
                    farmers.loading ? <Spin size="small" /> : null
                  }
                >
                  {farmers.result.map((f: any) => {
                    return (
                      <Option key={f.id} value={f.id}>
                        {f.name}-{f.rd}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Бүтээгдэхүүний төрөл">
                <Select
                  value={warehouseType}
                  onChange={(e: any) => setWarehouseType(e)}
                >
                  <Option value="harvest">Ургац</Option>
                  <Option value="natural">Байгалийн</Option>
                </Select>
              </Form.Item>
              {warehouseType == "harvest" && (
                <Form.Item
                  label="Талбайн дугаар"
                  name="farmlandId"
                  rules={[
                    { required: true, message: "Талбайн дугаар оруулна уу" },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={!licenseForm.getFieldValue("userId")}
                    placeholder="Талбайн дугаар"
                    style={{ width: "80%" }}
                    onChange={(e: any, a: any) => {
                      setFarmlandState(e);
                      setUsageId(a.usageId);
                      licenseForm.setFieldsValue({
                        warehousePlantId: undefined,
                      });
                    }}
                    filterOption={false}
                    onSearch={(value: string) => {
                      changeUserFarmlandFilter({
                        ...userFarmlandFilter,
                        posNo: (value ?? "").replace("НТД-", ""),
                      });
                    }}
                    options={userFarmland.result.map((f: any) => ({
                      key: f.farmland.id,
                      value: f.farmland.id,
                      label: `НТД-${f.farmland.posNo}`,
                    }))}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button onClick={showModal} disabled={usageId == 0}>
                  <HistoryOutlined /> Талбайн түүх
                </Button>
              </Form.Item>
              <Form.Item
                label="Таримлын мэдээлэл"
                name="warehousePlantId"
                rules={[{ required: true, message: "Таримал сонгоно уу" }]}
              >
                <Select
                  disabled={
                    warehouseType === "harvest"
                      ? !licenseForm.getFieldValue("farmlandId")
                      : false
                  }
                  mode="multiple"
                  placeholder="Таримал сонгоно уу"
                  onChange={(plantId) => {
                    let filter = {};
                    if (warehouseType) {
                      filter = {
                        ...filter,
                        type: warehouseType,
                      };
                    }
                    if (selectedOrganization) {
                      filter = {
                        ...filter,
                        ownerId: selectedOrganization,
                      };
                    }
                    if (farmlandState) {
                      filter = {
                        ...filter,
                        farmlandId: farmlandState,
                      };
                    }
                    changeUserSortWarehouseFilter({
                      ...filter,
                      "plantId[]": plantId,
                    });
                  }}
                >
                  {userPlantWarehouse.result.map((f) => {
                    return (
                      <Option key={f.id} value={f.id}>
                        {f.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Row style={{ marginBottom: 24, marginTop: 10 }}>
                <Col span={24}>
                  <Table
                    columns={sortColumns}
                    dataSource={userSort}
                    rowKey="id"
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>
              <Form.Item
                label="Зориулалт"
                name="purpose"
                rules={[{ required: true, message: "Зориулалт оруулна уу" }]}
              >
                <Select placeholder="Зориулалт" style={{ width: "80%" }}>
                  <Option value="sale">Худалдаа</Option>
                  <Option value="seed">Үрэнд</Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Хүлээн авагчийн нэр оруулна уу" },
                ]}
                label="Хүлээн авагчийн нэр"
                name="receiverName"
              >
                <Input placeholder={"нэр"} />
              </Form.Item>
              <Form.Item label="Хүлээн авагчийн утас" name="receiverPhone">
                <Input placeholder={"утасны дугаар"} />
              </Form.Item>
              <Form.Item
                label="Аймаг, хот"
                name="aimagId"
                rules={[{ required: true, message: "Хаяг сонгоно уу!" }]}
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  loading={aimag.loading}
                  onChange={(aimagId) => {
                    changeSum(aimagId);
                    licenseForm.setFieldsValue({
                      sumId: undefined,
                      bagId: undefined,
                    });
                  }}
                  placeholder="Аймаг сонгох"
                >
                  {aimag.result.map((rs: any) => {
                    return (
                      <Select.Option key={rs.id} value={rs.id}>
                        {rs.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Сум, дүүрэг"
                name="sumId"
                rules={[{ required: true, message: "Хаяг сонгоно уу!" }]}
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  loading={sum.loading}
                  onChange={(sumId) => {
                    changeBag(sumId);
                    licenseForm.setFieldsValue({
                      bagId: undefined,
                    });
                  }}
                  placeholder="Сум сонгох"
                >
                  {sum.result.map((rs: any) => {
                    return (
                      <Select.Option key={rs.id} value={rs.id}>
                        {rs.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Баг, хороо"
                name="bagId"
                rules={[{ required: true, message: "Хаяг сонгоно уу!" }]}
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  loading={bag.loading}
                  // onChange={och}
                  placeholder="Баг сонгох"
                >
                  {bag.result.map((rs: any) => {
                    return (
                      <Select.Option key={rs.id} value={rs.id}>
                        {rs.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Тээвэрлэгчийн нэр" name="transporterName">
                <Input placeholder={"Хариуцагчийн нэр:"} />
              </Form.Item>
              <Form.Item label="Тээвэрлэгчийн утас" name="transporterPhone">
                <Input placeholder={"Хариуцагчийн утас"} />
              </Form.Item>
              <Form.Item label="Тээврийн хэрэгсэл" name="transporterId">
                <Input placeholder={"Тээврийн хэрэгсэл"} />
              </Form.Item>
              <Form.Item
                label="Хүчинтэй хугацаа"
                name="date"
                rules={[
                  { required: true, message: "Хүчинтэй хугацаа оруулна уу" },
                ]}
              >
                <DatePicker.RangePicker
                  placeholder={["Эхлэх", "Дуусах"]}
                  format={"YYYY/MM/DD"}
                />
              </Form.Item>
              <Form.Item
                label="Техник үзлэгийн дүгнэлт"
                name="examinationDescription"
                rules={[
                  {
                    required: true,
                    message:
                      "Түүхий эд, бүтээгдэхүүн, таримлын үр, суулгацанд хийсэн мэдрэхүйн үзлэгийн дүн:!",
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 9 }}
                  placeholder={
                    "Түүхий эд, бүтээгдэхүүн," +
                    " таримлын үр, суулгацанд хийсэн мэдрэхүйн үзлэгийн дүн:"
                  }
                />
              </Form.Item>
              <Form.Item label="Тайлбар" name="description">
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={"Тайлбар"}
                />
              </Form.Item>
              <Form.Item label="Дүнгийн хуудас">
                <Form.Item
                  name="fileList"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: "Дүнгийн хуудас хавсаргана уу!",
                    },
                  ]}
                  noStyle
                >
                  {fileList.length > 0 ? (
                    <List
                      grid={{ gutter: 10, column: 8 }}
                      dataSource={fileList}
                      footer={
                        <Dragger
                          name="file"
                          showUploadList={false}
                          multiple={false}
                          action={`${host}/api/file/upoad`}
                          onChange={(info: any) => {
                            const { status } = info.file;
                            // if (status !== "uploading") {
                            //   console.log(info.file, info.fileList);
                            // }
                            if (status === "done") {
                              changeFileList((files: any[]) => {
                                return [
                                  ...files,
                                  {
                                    name: info.file.name,
                                    tmp: info.file.response.result.name,
                                    type: info.file.response.result.mimetype,
                                  },
                                ];
                              });
                              message.success(
                                `${info.file.name} файл амжилттай хууллаа.`
                              );
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} файл хуулхад алдаа гарлаа.`
                              );
                            }
                          }}
                        >
                          <Row>
                            <Col span={20}>
                              <p className="ant-upload-text">Дүнгийн хуудас</p>
                              <p className="ant-upload-hint">
                                Чирэх болон сонгож оруулна уу
                              </p>
                            </Col>
                            <Col span={4}>
                              <CloudDownloadOutlined />
                            </Col>
                          </Row>
                        </Dragger>
                      }
                      renderItem={(item) => {
                        return (
                          <List.Item>
                            {item.type == "application/pdf" ? (
                              <Card
                                bodyStyle={{ padding: 5, textAlign: "center" }}
                              >
                                <FilePdfOutlined style={{ fontSize: 50 }} />
                                <Popconfirm
                                  title="Устгахдаа итгэйлтэй байна уу."
                                  onConfirm={() => {
                                    const index = fileList.indexOf(item);
                                    const newFileList = fileList.slice();
                                    newFileList.splice(index, 1);
                                    changeFileList(newFileList);
                                  }}
                                  okText="Тийм"
                                  cancelText="Үгүй"
                                >
                                  <DeleteOutlined
                                    style={{
                                      position: "absolute",
                                      padding: 5,
                                      right: 0,
                                      top: 0,
                                      background: "white",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Popconfirm>
                              </Card>
                            ) : (
                              <Card bodyStyle={{ padding: 5 }}>
                                <Image
                                  width="100%"
                                  style={{ minWidth: 50, minHeight: 50 }}
                                  height="100%"
                                  src={`${host}/tmp/${item.tmp}`}
                                />
                                <Popconfirm
                                  title="Устгахдаа итгэйлтэй байна уу."
                                  onConfirm={() => {
                                    const index = fileList.indexOf(item);
                                    const newFileList = fileList.slice();
                                    newFileList.splice(index, 1);
                                    changeFileList(newFileList);
                                  }}
                                  okText="Тийм"
                                  cancelText="Үгүй"
                                >
                                  <DeleteOutlined
                                    style={{
                                      position: "absolute",
                                      padding: 5,
                                      right: 0,
                                      top: 0,
                                      background: "white",
                                      color: "red",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Popconfirm>
                              </Card>
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  ) : (
                    <Col span={24}>
                      <Dragger
                        name="file"
                        showUploadList={false}
                        multiple={false}
                        action={`${host}/api/file/upoad`}
                        onChange={(info: any) => {
                          const { status } = info.file;
                          // if (status !== "uploading") {
                          //   console.log(info.file, info.fileList);
                          // }
                          if (status === "done") {
                            changeFileList((files: any[]) => {
                              return [
                                ...files,
                                {
                                  name: info.file.name,
                                  tmp: info.file.response.result.name,
                                  type: info.file.response.result.mimetype,
                                },
                              ];
                            });
                            message.success(
                              `${info.file.name} файл амжилттай хууллаа.`
                            );
                          } else if (status === "error") {
                            message.error(
                              `${info.file.name} файл хуулхад алдаа гарлаа.`
                            );
                          }
                        }}
                      >
                        <Row>
                          <Col span={20}>
                            <p className="ant-upload-text">Дүнгийн хуудас</p>
                            <p className="ant-upload-hint">
                              Чирэх болон сонгож оруулна уу
                            </p>
                          </Col>
                          <Col span={4}>
                            <CloudDownloadOutlined />
                          </Col>
                        </Row>
                      </Dragger>
                    </Col>
                  )}
                </Form.Item>
              </Form.Item>
              <Form.Item noStyle initialValue="draft" name="status">
                <Input hidden />
              </Form.Item>
            </Form>
          </Col>
          <Col span={16} push={4}>
            <Row justify={"center"}>
              <Space size={20}>
                <Button
                  onClick={() => {
                    licenseForm.setFieldsValue({ status: "draft" });
                    licenseForm.submit();
                  }}
                  type="primary"
                >
                  Түр хадгалах
                </Button>
                {user?.role.cd !== USER_ROLE.CUSTOMER && (
                  <Button
                    onClick={() => {
                      licenseForm.setFieldsValue({ status: "active" });
                      licenseForm.submit();
                    }}
                    type="primary"
                  >
                    Батлах
                  </Button>
                )}
                {user?.role.cd === USER_ROLE.CUSTOMER && (
                  <Button
                    onClick={() => {
                      licenseForm.setFieldsValue({ status: "pending" });
                      licenseForm.submit();
                    }}
                    type="primary"
                  >
                    Илгээх
                  </Button>
                )}
              </Space>
            </Row>
          </Col>
        </Row>
      </Layout.Content>
      <UsageHistory
        visible={visible}
        onHide={() => setVisible(false)}
        usageId={usageId}
      />
    </>
  );
};
export default LicenseAdd;
interface ContentData {
  result: any[];
  loading: boolean;
}

type UserFarmlandFilter = {
  organization?: number;
  posNo?: string;
};

const useUserFarmland = (): [
  ContentData,
  () => void,
  UserFarmlandFilter,
  any
] => {
  const [filter, changeFilter] = useState<UserFarmlandFilter>({});
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    if (filter.organization) {
      loadContent();
    }
  }, [filter]);
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/farmland/list/by_organization",
      filter
    );
    changeContent({
      loading: false,
      result: response.result?.rows || [],
    });
  }, [filter]);
  return [content, loadContent, filter, changeFilter];
};

const useAddress = (): [ContentData, () => void, any] => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
  });
  const [parent, changeParent] = useState();
  useEffect(() => {
    if (parent) {
      loadContent();
    } else {
      changeContent({
        loading: false,
        result: [],
      });
    }
  }, [parent]);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet(`/api/address/${parent}`);
    changeContent({
      ...content,
      loading: false,
      result: response.result || [],
    });
  }, [parent]);

  return [content, loadContent, changeParent];
};

interface InputSizeProps {
  size: number;
  maxSize: number;
  onChange: any;
}

const InputSize = ({ size, maxSize, onChange }: InputSizeProps) => {
  const [v, setV] = useState(size);

  useEffect(() => {
    onChange(v);
  }, [v]);

  const changeHandler = (value?: string) => {
    if (!value) {
      setV(0);
      return;
    }
    if (maxSize >= +value) {
      setV(+value);
      return;
    }
    if (maxSize < +value) {
      setV(maxSize);
      return;
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  return (
    <InputNumber
      className="custom-number-input"
      value={`${v}`}
      formatter={(value?: string) =>
        `${value ?? 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      parser={(value?: string) => value!.replace(/(,*)/g, "")}
      onChange={debouncedChangeHandler}
    />
  );
};

interface HistoryProps {
  usageId: any;
  visible: boolean;
  onHide: any;
}
const UsageHistory = ({ visible, onHide, usageId }: HistoryProps) => {
  const [chemicalList, loadChemical, chemicalFilter] =
    useFarmlandChemicalList();
  const [serviceList, loadServiceList, serviceFilter] =
    useFarmlandServiceList();
  useEffect(() => {
    if (usageId) {
      chemicalFilter({
        usageId,
      });
      serviceFilter({
        usageId,
      });
    }
  }, [usageId]);
  return (
    <Modal
      onCancel={onHide}
      width={1000}
      centered
      visible={visible}
      footer={[
        <Button key="submit" type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Row justify="space-around" align="middle">
        <Col span={24}>
          <h2>Талбайн түүх</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            title={() => "Бодис, бордоо зарцуулалт"}
            pagination={false}
            columns={elementColumn}
            dataSource={chemicalList.result}
            scroll={{ x: true }}
          />
        </Col>
        <Col span={24}>
          <Table
            title={() => "Талбайн арчилгаа"}
            pagination={false}
            columns={chemColumn}
            dataSource={serviceList.result}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

const elementColumn = [
  {
    title: "№",
    render: (a: any, b: any, c: any) => c + 1,
  },
  {
    title: "Бодис, бордооны төрөл",
    render: (a: any) => a.chemical?.type?.name,
  },
  {
    title: "Бодис, бордооны нэр",
    render: (a: any) => a.chemical?.name,
  },
  {
    title: "Зориулалт",
    render: (a: any) => a.chemical?.description,
  },
  {
    title: "Хэрэглэсэн огноо",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Тун/га",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Бүртгэсэн ажилтан",
    render: (a: any) => a.user?.firstName,
  },
  {
    title: "Албан тушаал",
    render: (a: any) => a.user?.role?.name,
  },
];

const chemColumn = [
  {
    title: "№",
    render: (a: any, b: any, c: any) => c + 1,
  },
  {
    title: "Төрөл",
    render: (a: any) => a.service?.type?.name,
  },
  {
    title: "Нэр",
    render: (a: any) => a.service?.name,
  },
  {
    title: "1га-ийн тун/хэмжээ",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Огноо",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Бүртгэсэн ажилтан",
    render: (a: any) => a.user?.firstName,
  },
  {
    title: "Албан тушаал",
    render: (a: any) => a.user?.role?.name,
  },
];
