import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  Table,
  Breadcrumb,
  DatePicker,
  Layout,
  Select,
  Space,
  Upload,
  message,
  Form,
  InputNumber,
  PageHeader,
} from "antd";
import {
  RollbackOutlined,
  PlusSquareOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import BreadMe from "../lib/Breadme";
import useChemicalTypeList from "../../hooks/useChemicalTypeList";
import useChemical from "../../hooks/useChemical";
import NetworkApi from "../../network/networkApi";
import useFarmlandChemicalList from "../../hooks/useFarmlandChemicalList";
import useServiceType from "../../hooks/useServiceType";
import useService from "../../hooks/useService";
import useFarmlandServiceList from "../../hooks/useFarmlandServiceList";
import useChemicalWarehouse from "../../hooks/useChemicalWarehouse";
import useFarmlandPlantList from "../../hooks/useFarmlandPlantList";
import useFarmlandUsageDetail from "../../hooks/useFarmlandUsageDetail";
import useFarmlandServiceDetail from "../../hooks/useFarmlandServiceDetail";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

interface QueryParams {
  id: any;
}
const enumDosage = ["Илрээгүй", "Маш бага", "Бага", "Дунд", "Их"];
interface ModalState {
  visible: boolean;
  edit?: any;
}
const FarmLandService = () => {
  const { id } = useParams<QueryParams>();
  const [detail, loadDetail, changeId] = useFarmlandUsageDetail();
  const [chemicalModal, setChemicalModal] = useState<ModalState>({
    visible: false,
  });
  const [serviceModal, setServiceModal] = useState<ModalState>({
    visible: false,
  });
  const [chemicalList, loadChemical, chemicalFilter] =
    useFarmlandChemicalList();
  const [serviceList, loadServiceList, serviceFilter] =
    useFarmlandServiceList();
  useEffect(() => {
    if (id) {
      chemicalFilter({
        usageId: id,
      });
      serviceFilter({
        usageId: id,
      });
      changeId(id);
    }
  }, [id]);

  const elementColumn = [
    {
      title: "№",
      render: (a: any, b: any, c: any) => c + 1,
    },
    {
      title: "Бодис, бордооны төрөл",
      render: (a: any) => a.chemical?.type?.name,
    },
    {
      title: "Бодис, бордооны нэр",
      render: (a: any) => a.chemical?.technicalName,
    },
    {
      title: "Үндсэн таримал",
      render: (a: any) => a.farmlandPlant?.plant?.name,
    },
    {
      title: "Зориулалт",
      render: (a: any) => a.chemical?.purpose?.name,
    },
    {
      title: "Хэрэглэсэн огноо",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Талбайн хэмжээ",
      render: (value: any) => `${value.area}га`,
    },
    {
      title: "Хэмжээ",
      render: (value: any) => `${value.amount}${value.chemical?.unit?.name}`,
    },
    {
      title: "",
      render: (value: any) => (
        <Space size={10}>
          <a
            onClick={() => setChemicalModal({ visible: true, edit: value.id })}
          >
            <FormOutlined />
          </a>
        </Space>
      ),
    },
  ];

  const chemColumn = [
    {
      title: "№",
      render: (a: any, b: any, c: any) => c + 1,
    },
    {
      title: "Төрөл",
      render: (a: any) => a.service?.type?.name,
    },
    {
      title: "Нэр",
      render: (a: any) => a.service?.name,
    },
    {
      title: "Хэмжээ",
      render: (value: any) => {
        if (value.service?.unit == "enum") {
          return enumDosage.length <= value.dosage
            ? enumDosage[enumDosage.length - 1]
            : enumDosage[value.dosage - 1];
        }
        return `${value.dosage}${value.service?.unit}`;
      },
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      render: (value: any) => (
        <Space size={10}>
          <a onClick={() => setServiceModal({ visible: true, edit: value.id })}>
            <FormOutlined />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe
        title={["Тариалангийн талбай", "талбайд хийгдсэн арчилгаа, бодис"]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <PageHeader
          subTitle={`${detail.result?.area}га`}
          title={`НТДугаар: ${detail.result?.farmland?.posNo}`}
          extra={[
            <Button
              type={"primary"}
              icon={<RollbackOutlined />}
              onClick={() => window.history.back()}
            >
              Талбайн түүхрүү буцах
            </Button>,
            detail?.result.status === "active" && (
              <Button
                onClick={() => setChemicalModal({ visible: true })}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Бодис, бордоо зарцуулах
              </Button>
            ),
          ]}
          style={{ marginBottom: 15 }}
        />

        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              columns={elementColumn}
              dataSource={chemicalList.result}
            />
          </Col>
          <Col span={24}>
            <p>
              Бордоо, ургамал хамгааллын бодис /хог ургамал, хортон шавьж,
              өвчинтэй тэмцэх зорилгоор хэрэглэсэн/ хэд хэдэн удаа хэрэглэсэн
              бол тухай бүрийг хэрэглэсэн тун, хугацааг бичнэ.
            </p>
          </Col>
        </Row>
        <Row gutter={20} justify={"end"}>
          <Col span={24}>
            <Space size={240}>
              {detail?.result.status === "active" && (
                <Button
                  onClick={() => setServiceModal({ visible: true })}
                  icon={<PlusSquareOutlined />}
                  type={"primary"}
                >
                  Арчилгаа нэмэх
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              columns={chemColumn}
              dataSource={serviceList.result}
            />
          </Col>
        </Row>
      </Layout.Content>
      {chemicalModal.visible && (
        <FarmlandChemicalAdd
          usage={detail.result}
          id={chemicalModal.edit}
          visible={chemicalModal.visible}
          handleOk={() => {
            setChemicalModal({ visible: false });
            loadChemical();
          }}
        />
      )}
      {serviceModal.visible && (
        <FarmlandServiceAdd
          usage={detail.result}
          id={serviceModal.edit}
          visible={serviceModal.visible}
          handleOk={() => {
            setServiceModal({ visible: false });
            loadServiceList();
          }}
        />
      )}
    </>
  );
};

const FarmlandServiceAdd = ({ visible, handleOk, id, usage }: any) => {
  const [typeList] = useServiceType();
  const formRef = useRef<any>();
  const [serviceList, loadChemical, chemicalFilter] = useService();
  const [selectedService, setSelectedService] = useState("");
  const [detail, loadDetail, changeDetailId] = useFarmlandServiceDetail();
  useEffect(() => {
    if (visible) {
      if (id) {
        console.log(id);
        changeDetailId(id);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [id, visible]);
  useEffect(() => {
    if (!detail.loading) {
      formRef.current?.setFieldsValue({
        serviceType: detail.result.service?.typeId,
        serviceId: detail.result.serviceId,
        date: moment(detail.result.date),
        dosage: detail.result.dosage,
      });
    }
  }, [detail]);
  const onFinish = useCallback(
    async (data: any) => {
      let response = await NetworkApi.requestPost(
        "/api/farmland/service/update",
        {
          ...data,
          id,
          usageId: usage.id,
        }
      );
      if (response.resultCode == 200) {
        handleOk();
      } else {
        message.error(response.message || "system error");
      }
    },
    [usage, id]
  );
  const currentService = useMemo(() => {
    if (selectedService) {
      return serviceList.result.find(
        (service) => service.id == selectedService
      );
    }
    return {};
  }, [selectedService, serviceList]);
  return (
    <Modal
      width={794}
      centered
      visible={visible}
      onCancel={handleOk}
      title={`НТДугаар:${usage.farmland?.posNo} - Арчилгаа ${
        id ? "засах" : "нэмэх"
      }`}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formRef.current?.submit()}
        >
          Хадгалах
        </Button>,
        <Button key="submit" type="default" onClick={handleOk}>
          Хаах
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form
            onFinish={onFinish}
            ref={formRef}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              name="serviceType"
              initialValue=""
              label="Арчилгааны төрөл"
            >
              <Select onChange={(v) => chemicalFilter({ type: v })}>
                <Select.Option value="">Арчилгааны төрөл</Select.Option>
                {typeList.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="serviceId" initialValue="" label="Нэр">
              <Select onChange={(e: any) => setSelectedService(e)}>
                <Select.Option value={""}>Арчилгааны нэр</Select.Option>
                {serviceList.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="date" label="Огноо">
              <DatePicker placeholder={"Огноо"} />
            </Form.Item>
            {currentService?.unit == "enum" ? (
              <Form.Item name="dosage" label="Хэмжээ">
                <Select>
                  <Select.Option value={1}>Илрээгүй</Select.Option>
                  <Select.Option value={2}>Маш бага</Select.Option>
                  <Select.Option value={3}>Бага</Select.Option>
                  <Select.Option value={4}>Дунд</Select.Option>
                  <Select.Option value={5}>Их</Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="dosage" label="Хэмжээ">
                <InputNumber
                  style={{ width: "80%" }}
                  placeholder={"Тунгийн хэмжээ"}
                  parser={(value) => value?.replace(",", ".") || ""}
                />
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

const FarmlandChemicalAdd = ({ visible, handleOk, id, usage }: any) => {
  const [typeList] = useChemicalTypeList();
  const [form] = useForm();
  const [chemicalWarehouse, loadChemicalWarehouse, changeWarehouseFilter] =
    useChemicalWarehouse();
  const [plantList, loadPlant, filterPlant] = useFarmlandPlantList();
  const [warehouseId, changeWarehouse] = useState("");
  const [selectedChemicalType, changeSelectedChemicalType] = useState<any>("");
  useEffect(() => {
    if (visible && id) {
      loadDetail(id);
    }
    if (visible) {
      filterPlant({
        usage: usage.id,
      });
    }
  }, [usage, visible, id]);

  const [remaining, changeRemaining] = useState<number | undefined>();

  useEffect(() => {
    if (!visible) {
      changeRemaining(undefined);
      form.resetFields();
    }
  }, [visible]);

  const loadDetail = useCallback(async (chemicalId: any) => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/chemical/detail/${chemicalId}`
    );
    if (response.resultCode == 200) {
      console.log(response.result);
      form.setFieldsValue({
        ...response.result,
        date: moment(response.result.date),
      });
    }
  }, []);
  const onFinish = useCallback(
    async (data: any) => {
      if (data.date) {
        data.date = data.date.format("YYYY-MM-DD");
      }
      let reqData = {
        ...data,
        id,
        usageId: usage.id,
        type: "care",
      };
      let response = await NetworkApi.requestPost(
        "/api/farmland/chemical/update",
        reqData
      );
      if (response.resultCode == 200) {
        handleOk();
      } else {
        message.error(response.message || "system error");
      }
    },
    [usage, id]
  );
  const selectedWarehouse = useMemo(() => {
    const v =
      chemicalWarehouse.result.find((w) => w.id == parseInt(warehouseId)) || {};
    console.log(v);
    return v;
  }, [warehouseId, chemicalWarehouse]);
  return (
    <Modal
      width={794}
      centered
      visible={visible}
      onCancel={handleOk}
      title={`НТДугаар:${usage.farmland?.posNo} - Бодис, бордоо ${
        id ? "зарцуулалт засах" : "зарцуулах"
      }`}
      footer={[
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Хадгалах
        </Button>,
        <Button key="submit" type="default" onClick={handleOk}>
          Хаах
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form
            onFinish={onFinish}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item initialValue="" label="Бодис, бордооны төрөл">
              <Select onChange={(v) => changeWarehouseFilter({ type: v })}>
                <Select.Option value="">Бодис, бордооны төрөл</Select.Option>
                {typeList.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="warehouseId" initialValue="" label="Агуулах">
              <Select
                value={warehouseId}
                onChange={(v) => {
                  const license = chemicalWarehouse.result.find(
                    (item) => item.id == v
                  );
                  changeSelectedChemicalType(license?.chemical?.type);
                  changeWarehouse(v);
                  changeRemaining(license?.remaining ?? undefined);
                }}
              >
                <Select.Option value={""}>Бодис, бордооны нэр</Select.Option>
                {chemicalWarehouse.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.chemical?.technicalName}-{t.remaining}
                    {t.chemical?.unit?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Зориулалт">
              <Select disabled value="">
                <Select.Option value={""}>
                  {selectedWarehouse.chemical?.description || ""}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="farmlandPlantId"
              initialValue=""
              label="Үндсэн таримал"
            >
              <Select>
                <Select.Option value={""}>Үндсэн таримал</Select.Option>
                {plantList.result.map((t) => (
                  <Select.Option key={t.id} value={t.id}>
                    {t.plant?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="date" label="Хэрэглэсэн огноо">
              <DatePicker placeholder={"Огноо"} />
            </Form.Item>
            <Form.Item name="area" label="Талбайн хэмжээ/га">
              <InputNumber
                parser={(value) => value?.replace(",", ".") || ""}
                style={{ width: "80%" }}
                placeholder={"хэмжээ"}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label="Зарцуулсан хэмжээ"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || (remaining && remaining >= Number(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Зарцуулах хэмжээ үлдэгдлээс хэтэрсэн байна!")
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                parser={(value) => value?.replace(",", ".") || ""}
                style={{ width: "80%" }}
                placeholder={"хэмжээ"}
              />
            </Form.Item>
            {selectedChemicalType?.hasParcel && (
              <Form.Item name="parcel" label="Сав баглаа">
                <InputNumber
                  parser={(value) => value?.replace(/[^0-9]/g, "") || ""}
                  style={{ width: "80%" }}
                  placeholder={"хэмжээ"}
                />
              </Form.Item>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default FarmLandService;
